<template>
  <div>
    <div>
      <b-card>
        <div class="row mb-3">
          <div class="col-12">
            <ModalActions @close="$router.go(-1)" />
            <br />
          </div>
          <div class="col-12 d-flex">
            <span class="h2 mr-auto text-secondary"
              ><i :class="$route.meta.iconClass"></i> Nouvelle entité</span
            >
            <div>
              <b-button
                pill
                @click.prevent="addEntity"
                :disabled="submitingForm"
              >
                <b-spinner
                  small
                  v-if="submitingForm"
                  class="text-white"
                  label="Loading..."
                ></b-spinner
                >Confirmer</b-button
              >
            </div>
          </div>
        </div>
        <div class="">
          <div class="col-12 d-flex p-0 mt-3 mt-md-0 gap">
            <div style="flex: 1" class="shdow ml-md-6">
              <!------------------------------------- ---------------------->

              <div
                class="
                  d-flex
                  flex-wrap
                  justify-content-between
                  align-items-center
                "
              >
                <span class="h4 text-secondary">Informations générales</span>
              </div>
              <hr class="mt-1 mb-3 bg-secondary" />

              <div class="row">
                <div class="col-12 col-md-12">
                  <b-form-group
                    class="font-weight-bold"
                    label-cols="4"
                    label-cols-lg="3"
                    label="Libellé :"
                  >
                    <b-form-input
                      placeholder="Libellé"
                      v-model="libelle"
                      :class="{
                        'is-invalid': $v.libelle.$error && $v.libelle.$dirty,
                      }"
                      type="text"
                    >
                      ></b-form-input
                    >
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.libelle.$dirty"
                    >
                      {{
                        !$v.libelle.required
                          ? "Champ obligatoire"
                          : !$v.libelle.minLength
                          ? `Le champ doit contenir au moins ${$v.libelle.$params.minLength.min} caractères.`
                          : ""
                      }}
                    </span>
                  </b-form-group>
                </div>
                <!-- <div class="col-12 col-md-12">
                  <b-form-group
                    class="font-weight-bold"
                    label-cols="4"
                    label-cols-lg="3"
                    label="Organisation :"
                  >
                    <multiselect
                      multiple
                      v-model="selectedOrganisation"
                      :options="organisations"
                      label="text"
                      track-by="value"
                      placeholder="Choisir les Organisation"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group
                    class="font-weight-bold"
                    label-cols="4"
                    label-cols-lg="3"
                    label="Acteurs :"
                  >
                    <multiselect
                      multiple
                      v-model="acteurs"
                      :options="responsables"
                      label="text"
                      track-by="value"
                      placeholder="Choisir les Acteurs"
                    ></multiselect>
                  </b-form-group>
                </div> -->
              </div>
            </div>

            <div style="flex: 1" class="shdow ml-md-6">
              <div
                class="
                  d-flex
                  flex-wrap
                  justify-content-between
                  align-items-center
                "
              >
                <span class="h4 text-secondary">Couverture</span>
              </div>
              <hr class="mt-1 mb-3 bg-secondary" />

              <div class="row m-0 pt-4">
                <div
                  class="
                    col-12
                    d-flex
                    justify-content-between
                    align-items-center
                    mb-3
                    px-0
                  "
                ></div>
              </div>

              <Places
                :testCouverture="sendingFunction"
                style="margin-top: -40px"
              />
              <div class="row m-0 p-0">
                <div
                  style="height: 50px; position: relative; overflow: visible"
                  class="shdow"
                  v-for="(covert, index) in couvertures"
                  :key="index"
                >
                  <p>
                    <country-flag
                      :country="covert.countryCode"
                      size="small"
                      v-show="covert.countryCode"
                    />{{ covert.country }}
                  </p>

                  <div style="position: absolute; top: -5px; right: -5px">
                    <span style="cursor: pointer"
                      ><i
                        @click="deleteCouverture(index)"
                        class="mdi mdi-close-circle text-danger"
                      ></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div class="my-4">
          <b-tabs
            :value="$store.state.tabs.activeTab"
            @changed="activeTab = $store.state.tabs.activeTab"
            @activate-tab="
              (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab title="Organisations">
              <div class="row">
                <div class="col-12">
                  <entity-organisation :disabled="true" />
                </div>
              </div>
            </b-tab>
            <b-tab title="Acteurs">
              <div class="row">
                <div class="col-12">
                  <entity-actor :actorEntity="[]" :disabled="true" />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import countries from "@/assets/countries/data.json";
import ModalActions from "@/components/custom/ModalActions.vue";
import EntityOrganisation from "../components/EntityOrganisation.vue";
import EntityActor from "../components/EntityActor.vue";
import { mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";
import Places from "@/components/custom/Places.vue";
import { required, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {
  ENTITE_URL,
  USER_URL,
  ORGANISATION_URL,
  COUVERTURE_URL,
} from "@/helpers/constURL";

export default {
  components: {
    ModalActions,
    CountryFlag,
    Places,
    EntityOrganisation,
    EntityActor,
  },
  data: () => ({
    countries: countries,
    selectedCountries: [],
    submitingForm: false,
    selectedOrganisation: [],
    libelle: "",
    acteurs: [],
    couvertures: [],
    idCouverture: [],
  }),
  validations: {
    libelle: {
      required,
      minLength: minLength(5),
    },
  },
  methods: {
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    deleteCouverture(index) {
      this.idCouverture[index] = this.couvertures[index].id;

      //  this.delete = true;
      this.couvertures.splice(index, 1);
    },
    sendingFunction: function (location) {
      if (
        !this.couvertures.find(
          (couverture) => couverture.countryCode === location.countryCode
        )
      )
        this.couvertures.push(this.clone(location));
      else
        Swal.fire(
          "Impossible d'ajouter une couverture déjà existante !",
          "",
          "error"
        );
    },
    addEntity() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$store
          .dispatch("entity/createEntity", {
            libelle: this.libelle,
          })
          .then(() => {
            if (this.couvertures.length) {
              this.couvertures.forEach((element) => {
                this.$store.dispatch("couverture/createCouverture", {
                  country: element.country,
                  countryCode: element.countryCode,
                  lat: element.lat,
                  lng: element.lng,
                  adresse: element.adresse,
                  entite: `/api/${ENTITE_URL}/${this.ENTITIE.id}`,
                });
              });
              Swal.fire({
                title: "L'entité est bien créée !",
                type: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.value) {
                  this.$router.push({
                    name: "show-entity",
                    params: {
                      slug: this.ENTITIE.slug || "abcd",
                      id: this.ENTITIE.id,
                      fromCreation: true,
                    },
                  }); //redirect to the new task
                }
              });
            }
          });
      }
    },
  },
  watch: {},

  created() {
    this.$store.dispatch("couverture/fetchAllCouvertures");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("users/fetchAllResponsables");
  },

  computed: {
    ...mapGetters("couverture", ["COUVERTURE"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("entity", ["ENTITIE"]),

    couverture() {
      return this.COUVERTURE.map((couverture) => ({
        value: `/api/${COUVERTURE_URL}/${couverture.id}`,
        text: couverture.country,
      }));
    },

    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    organisations() {
      return this.ORGANISATIONS.map((org) => ({
        value: `/api/${ORGANISATION_URL}/${org.id}`,
        text: org.libelle,
      }));
    },
  },
};
</script>

<style>
.gap {
  gap: 2%;
}
</style>
